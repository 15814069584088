<template>
  <v-container>
    <BaseTitlePage>
      <template v-slot:button>
        <BaseButton
          id="btn-create"
          name="btn-create"
          title="Novo cadastro"
          icon="fa-solid fa-plus"
          color="secondary"
          @click="create"
          v-permission="{
            roles: ['A1', 'AC1', 'AC2', 'AC3', 'ACI1', 'ACI2', 'ACI3'],
            operator: '==='
          }"
        />
      </template>
    </BaseTitlePage>

    <BaseSearchPage
      title="Consultar e filtrar operadora e/ou seguradora existente"
    >
      <template v-slot:form>
        <v-form @submit.prevent="search" ref="form" lazy-validation>
          <v-row dense>
            <v-col cols="12" xl="4" lg="4" md="4" sm="6">
              <v-text-field
                id="name"
                name="name"
                label="Razão social | Nome fantasia"
                v-model="filter.name"
                dense
                outlined
                hide-details
                clearable
              />
            </v-col>
            <v-col cols="12" xl="2" lg="2" md="2" sm="6">
              <v-text-field
                id="document"
                name="document"
                label="CNPJ"
                hide-details
                clearable
                dense
                outlined
                v-mask="'##.###.###/####-##'"
                v-model="filter.document"
              />
            </v-col>
            <v-col cols="12" xl="2" lg="2" md="2" sm="6">
              <BaseProductType
                id="product"
                name="product"
                label="Produto"
                hide-details
                clearable
                v-model="filter.product"
              />
            </v-col>
            <v-col cols="12" xl="2" lg="2" md="2" sm="6">
              <v-autocomplete
                id="situation"
                name="situation"
                label="Status"
                clearable
                dense
                outlined
                hide-details
                :items="situation"
                v-model="filter.situation"
              />
            </v-col>
            <v-col cols="4" xl="2" lg="2" md="2" sm="12">
              <BaseButton
                id="btn-search"
                name="btn-search"
                title="Filtrar"
                icon="fa-solid fa-magnifying-glass"
                type="submit"
                height="40"
                color="primary"
              />
            </v-col>
          </v-row>
        </v-form>
      </template>
    </BaseSearchPage>

    <BaseTableList title="Listagem de operadoras e seguradoras">
      <template v-slot:table>
        <v-data-table
          dense
          disable-sort
          item-key="id"
          :headers="headers"
          :items="items"
          :options.sync="options"
          :server-items-length="totalRecords"
          :items-per-page="10"
          :footer-props="{
            itemsPerPageOptions: [10, 20, 50, 100],
            showFirstLastPage: true,
            firstIcon: 'first_page',
            lastIcon: 'last_page',
            prevIcon: 'chevron_left',
            nextIcon: 'chevron_right'
          }"
        >
          <template v-slot:[`item.hasHealthProduct`]="{ item }">
            <v-icon
              :color="item.hasHealthProduct ? 'success' : 'error'"
              size="21"
            >
              {{
                item.hasHealthProduct
                  ? 'fa-regular fa-circle-check'
                  : 'fa-regular fa-circle-xmark'
              }}
            </v-icon>
          </template>

          <template v-slot:[`item.hasLifeProduct`]="{ item }">
            <v-icon
              :color="item.hasLifeProduct ? 'success' : 'error'"
              size="21"
            >
              {{
                item.hasLifeProduct
                  ? 'fa-regular fa-circle-check'
                  : 'fa-regular fa-circle-xmark'
              }}
            </v-icon>
          </template>

          <template v-slot:[`item.hasDentalProduct`]="{ item }">
            <v-icon
              :color="item.hasDentalProduct ? 'success' : 'error'"
              size="21"
            >
              {{
                item.hasDentalProduct
                  ? 'fa-regular fa-circle-check'
                  : 'fa-regular fa-circle-xmark'
              }}
            </v-icon>
          </template>

          <template v-slot:[`item.document`]="{ item }">
            {{ formatCnpj(item.document) }}
          </template>

          <template v-slot:[`item.situation`]="{ item }">
            <span :class="getColor(item.situation)">{{ item.situation }}</span>
          </template>

          <template v-slot:[`item.action`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  color="success"
                  small
                  @click="edit(item)"
                  v-permission="{
                    roles: [
                      'AC1',
                      'AC2',
                      'AC3',
                      'ACI1',
                      'ACI2',
                      'ACI3',
                      'F1',
                      'F2',
                      'F3',
                      'A1'
                    ],
                    operator: '==='
                  }"
                >
                  fa-solid fa-pencil
                </v-icon>
              </template>
              <span>Visualizar</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </template>
    </BaseTableList>
  </v-container>
</template>

<script>
import { getColor, unmask } from '@/helpers/utils';
import { formatCnpj } from '@/helpers/formatting';
import { InsuranceCompanyService } from '@/services/api/insuranceCompany';

export default {
  data: () => ({
    situation: ['Ativo', 'Cancelado'],
    items: [],
    totalRecords: 0,
    filter: {},
    options: {},
    headers: [
      { text: 'Operadora/Seguradora', value: 'socialName' },
      { text: 'CNPJ', value: 'document' },
      { text: 'Saúde', value: 'hasHealthProduct' },
      { text: 'Vida', value: 'hasLifeProduct' },
      { text: 'Odonto', value: 'hasDentalProduct' },
      { text: 'Status', value: 'situation' },
      { text: '', value: 'action', width: '10%' }
    ]
  }),

  methods: {
    formatCnpj,
    getColor,

    async search() {
      const { page, itemsPerPage } = this.options;
      const { name, document, product, situation } = this.filter;

      try {
        const insuranceCompanyService = new InsuranceCompanyService();
        const { status, data } = await insuranceCompanyService.getInsurances({
          page,
          take: itemsPerPage,
          name,
          document: unmask(document),
          product,
          situation
        });

        if (status === 200) {
          this.items = data.results;
          this.totalRecords = data.totalRecords;
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    create() {
      this.$router.push({ name: 'operadoras-edit' });
    },

    edit(item) {
      this.$router.push({
        name: 'operadoras-edit',
        params: { id: item.id, cnpj: item.document }
      });
    }
  },

  watch: {
    options: {
      handler() {
        this.search();
      },
      deep: true
    }
  }
};
</script>
